import * as React from "react"
import { GrayText, H1,HeaderText2, HeaderText3 } from "../elements/shared/texts"
import { AccentButton } from "../elements/shared/buttons"
import styled from "styled-components"
import tw from "tailwind.macro"
import SubscriptionComponent from "../components/SubscriptionComponent"

const Container = styled.div`
  ${tw` flex flex-col text-center py-32`};
`

export function ListHeader(props) {
  return (
      <Container>
        <HeaderText2 className="py-4" >{props.title}</HeaderText2>
        <div>    
            <SubscriptionComponent />
        </div>
      </Container>
  )
}

export default ListHeader