import React from 'react';
import { UserLoggedInState } from "../utils/constants";
import { motion } from "framer-motion"
import {
    DangerButtom,
    DropdownContent,
    DropdownMenu,
    PrimaryLink,
  } from "../elements/shared/buttons"
  import { NavLink } from "../elements/shared/texts"
  import { FiSmile } from "react-icons/fi"

const DynamicAuthComponent = props => {


    switch (props.loggedInState) {
        case UserLoggedInState.LOGGED_IN:
            return (
                <DropdownMenu className={"ml-2 "}>
                <motion.div
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.1, rotate: 60 }}
                >
                  <FiSmile size={30} />
                </motion.div>

                <DropdownContent>
                  <motion.div
                    className={"sm:p-2 rounded-lg"}
                    whileTap={{ scale: 0.95 }}
                    whileHover={{
                      scale: 1.1,
                      color: props.theme.colors.primary,
                      backgroundColor: props.theme.colors.lightPrimary,
                    }}
                  >
                    <NavLink to={"/account/dashboard"}> DASHBOARD </NavLink>
                  </motion.div>
                  <DangerButtom
                    whileTap={{ scale: 0.95 }}
                    whileHover={{
                      scale: 1.1,
                    }}
                    className={"block  px-2 mt-2 rounded-lg"}
                    onClick={props.signOut}
                  >
                    SIGN OUT{" "}
                  </DangerButtom>
                </DropdownContent>
              </DropdownMenu>
            )

        case UserLoggedInState.NOT_LOGGED_IN:
            return (
              <motion.div
                whileHover={{ scale: 1.1,  y: -5  }}
                whileTap={{ scale: 0.9, y: 0}}
              >
                  <PrimaryLink to={`/auth`} className={"ml-2"}>
                  LOGIN
                </PrimaryLink>
              </motion.div>
            )
    
        case UserLoggedInState.LOADING:
        return null

    }
}

export default DynamicAuthComponent 