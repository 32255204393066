import React from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import { motion, useViewportScroll } from "framer-motion"
import { FiSmile } from "react-icons/fi"
import {
  OutlineGrayLink,
  PrimaryLink,
  LogoutButton
} from "../shared/buttons"
import { NavLink } from "../shared/texts"
import {
  UseGlobalDispatchContext,
  UseGlobalStateContext,
} from "../../context/globalContext"
import { getTheme } from "../../themes/theme"
import StorageService from "../../helpers/StorageService"
import { navigate } from "@reach/router"
import MenuItem from "../../components/MenuItem"
import { GlobalActionsType } from "../../utils/constants"
import AuthenticationService from "../../web_service/AuthenticationService"
import { IsBrowser } from "../../helpers/utils"
import { FaMoon } from "react-icons/fa"
import { MdWbSunny } from "react-icons/md"

export const Navbar = styled(motion.nav)`
  ${tw`flex justify-between items-center pt-3 z-50 w-full xl:px-0 lg:px-0 md:px-0 px-4`};
  transition: background 0.5s, color 0.5s;

  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.colors.background};
    `}
`

export const Logo = styled("img")`
  height: 44px;
`

export const ThemeButton = styled(motion.button)`
 ${tw`pr-4 md:pr-0`};
  z-index: 15;
`

export const ThemeIcon = styled(motion.img)`
  height: 20px;
`

export const LoginButton = styled(motion.button)`
  ${tw`block mx-auto py-2 px-8 text-white font-semibold text-lg sm:text-sm lg:text-md`};

  border-radius: 15px;
`

export const MobileNav = styled(motion.div)`
  ${tw`flex  justify-between  items-center`}
`

export const MenuContainer = styled(motion.div)`
  ${tw`items-center z-10 justify-center md:static absolute left-0 right-0  md:w-auto md:h-auto  w-screen h-screen`};
  top: 0;
  height: 0;
  display: flex;
  font-size:  max(1em, min(6vw, 2rem));
  font-weight: bold;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 0.97, 0.25, 1);
  background-color: ${props => props.theme.colors.background};
  
  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      height: 100vh;
      display: flex;

      div{
        display:block;
      }

    `};

    a {
      font-size: max(1em, min(6vw, 2rem));
      margin-top: 20px;
    
    }

    button {
      font-weight: bold;
    }

    ${props =>
    !props.isOpen &&
    css`

      div{
        display:none;
      }

    `};


  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: ${props => props.theme.measurements.xlFontSize};
    a {
      font-size: ${props => props.theme.measurements.xlFontSize};
      margin-top: 0;
    }
  }
`

export const MenuItems = ({ switchTheme, login, themeIconURL, isLoggedIn }) => {
  const { currentTheme } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)
  const dispatch = UseGlobalDispatchContext()

  const signOut = () => {
    AuthenticationService.getService().logout(() => {
      dispatch({ type: GlobalActionsType.IS_LOGGED_IN, isLoggedIn: false })
      dispatch({ type: GlobalActionsType.USER, user: false })
      if (IsBrowser) {
        navigate("/")
      }
    })
  }

  return (
    <motion.div
      className={
        "sm:flex sm:items-center inline-block subpixel-antialiased  pb-20 sm:pb-0"
      }
    >
      <div className="sm:hidden">
        <MenuItem path="/" isNav={"true"} title={"HOME"} />
        <MenuItem path="/courses" isNav={"true"} title={"COURSES"} />
        <MenuItem path="/blog" isNav={"true"} title={"BLOG"} />
        <MenuItem path="#pricing" isExternal={"true"}  isNav={"true"} title={"PRICING"} />
        <MenuItem path="#work" isExternal={"true"}  isNav={"true"} title={"WORK"} />
        
        { isLoggedIn && (
              <motion.div
                className={"sm:p-2 rounded-lg"}
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1}}
              >
                <OutlineGrayLink to={"/account/dashboard"}>
                  DASHBOARD
                </OutlineGrayLink>
              </motion.div>
            )}

            { isLoggedIn && (
              <LogoutButton
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1 }}
                onClick={signOut}
              >
                LOGOUT
              </LogoutButton>
            )}

            { !isLoggedIn && (
              <PrimaryLink to={`/auth`} >
                LOGIN
              </PrimaryLink>
            )}
      </div>
    </motion.div>
  )
}
